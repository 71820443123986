//==
//== Select
//== ======================================= ==//

(function () {

    let hasGlobalEvent = false;

    const classBlock = 'simple-select';

    const classElement     = `${classBlock}__element`;
    const classPlaceholder = `${classBlock}__placeholder`;
    const classText        = `${classBlock}__text`;
    const classList        = `${classBlock}__list`;
    const classOption      = `${classBlock}__option`;

    const classOpen = `${classBlock}_open`;

    jQuery.fn.simpleSelect = function () {
        return this.each(function () {

            const $el = $(this);

            if (!!+$el.data('init')) return;

            $el.data('init', 1);

            const $element     = $el.find(`.${classElement}`);
            const $placeholder = $el.find(`.${classPlaceholder}`);
            const $text        = $el.find(`.${classText}`);
            const $list        = $el.find(`.${classList}`);

            $placeholder.on('click', function () {
                if (!$el.hasClass(classOpen)) {
                    $el.addClass(classOpen);
                    $(`.${classBlock}.${classOpen}`).not($el).removeClass(classOpen);
                } else {
                    $el.removeClass(classOpen);
                }
            });

            $list.on('click', `.${classOption}`, function () {
                const $option = $(this);
                $el.removeClass(classOpen);
                $text.text($option.text());
                $element.val($option.attr('data-value'));
            });

            if (!hasGlobalEvent) {
                hasGlobalEvent = true;

                $(document).on('click', function (event) {
                    if (!$(event.target).closest(`.${classBlock}`).length) {
                        $(`.${classBlock}.${classOpen}`).removeClass(classOpen);
                    }
                });
            }
        });
    };

})();
