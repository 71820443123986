//==
//== Viewport lock/unlock
//== ======================================= ==//

(function () {

    let lockCounter = 0;

    App.lock = function () {
        lockCounter++;

        if (lockCounter > 0) {
            App.$body.addClass(App.bodyLockClass);
            App.$body.css({ marginRight: this.getScrollbarWidth() + 'px' });
        }
    };

    App.unlock = function () {
        lockCounter--;

        if (lockCounter < 1) {
            App.$body.removeClass(App.bodyLockClass);
            App.$body.css({ marginRight: '' });
        }
    };

})();
