//==
//== Боковое слайд-меню
//== ======================================= ==//

(function () {

    const classOpen = 'open';

    const $sm = $('.menu-block > ul');

    if (!$sm.length) return;

    $sm.on('click', 'button', function () {
        let $item = $(this).closest('li');

        $item
            .toggleClass(classOpen)
            .children('ul').slideToggle()
        ;
        $item
            .siblings('li').not($item).find('ul').slideUp()
            .parent('li').removeClass(classOpen)
        ;
    });

    $sm.find(`.${classOpen}`).children('ul').show();

})();
