//==
//== Item added into cart
//== ======================================= ==//

App.messageBox = function (type) {
    switch (type) {
        case 'shop':
        case 'cart':
        case 'basket':
            $('#item-added').modal('show');
            break;

        case 'ok':
        case 'success':
            $('#info-success').modal('show');
            break;

        case 'fail':
        case 'error':
            $('#info-fail').modal('show');
            break;
    }
};

App.quickOrder = function (title, image) {

    const $modal = $('#modal-quick');
    $('.scf-table__photo', $modal).attr('src', image);
    $('.scf-table__cell-title', $modal).text(title);

    $modal.modal('show');

};

// App.quickOrder('Трубы ПНД (ПЭ) ГОСТ 18599', '/design/images/content/slide-1-330x260.jpg');
// App.messageBox('error');
