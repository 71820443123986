//==
//== Accordions
//== ======================================= ==//

(function () {

    const classBlock = 'accordion';

    const classHeader   = `${classBlock}__header`;
    const classBody   = `${classBlock}__body`;
    const classToggle = `${classBlock}__toggle`;

    const classOpen = `${classBlock}_open`;

    jQuery.fn.accordion = function () {
        return this.each(function () {

            const $el = $(this);

            if (!!+$el.data('init')) return;

            $el.data('init', 1);

            const $body   = $el.find(`.${classBody}`);
            const $header = $el.find(`.${classHeader}`);
            const $button = $el.find(`.${classToggle}`).add($header);

            $button.on('click', function (e) {
                e.stopPropagation();
                if ($el.hasClass(classOpen)) {
                    $el.removeClass(classOpen);
                    $body.slideUp();
                } else {
                    $el.addClass(classOpen);
                    $body.slideDown();
                }
            });

            if ($el.hasClass(classOpen)) {
                $body.slideDown();
            }

        });
    };

})();
