//==
//== Поле прикрепления файла
//== ======================================= ==//

jQuery.fn.uploadfile = function () {
    return this.each(function () {

        let $el = $(this);

        if (!!+$el.data('init')) return;

        $el.data('init', 1);

        let pickedFileName = '';
        let pickedFileSize = '';
        let fieldName;

        let $button   = $('.upload-file__button', $el);
        let $file     = $('.upload-file__file', $el);
        let $filename = $('.upload-file__filename', $el);
        let $clear    = $('.upload-file__clear', $el);

        $file.hide();

        function onChange(e) {
            fieldName = e.target.name;
            if (e.target.files.length && e.target.files[0].name) {
                const pickedFile = e.target.files[0];
                pickedFileName   = pickedFile.name;

                const nBytes     = pickedFile.size;
                const aMultiples = ['KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
                let nMultiple    = 0, nApprox = nBytes / 1024;
                for (; nApprox > 1; nApprox /= 1024, nMultiple++) {
                    pickedFileSize = nApprox.toFixed(1) + ' ' + aMultiples[nMultiple];
                }

                $button.hide();
                $file.show();
                $filename.html(pickedFileName + ' <small>(' + pickedFileSize + ')</small>');
            }
        }

        $el.find('input[type="file"]').on('change', onChange);

        $clear.on('click', function () {
            $el.find('input[type="file"]').remove();
            const $newField = $('<input>', {
                type : 'file',
                class: 'upload-file__element',
                name : fieldName,
            });
            $newField.on('change', onChange);
            $newField.appendTo($button);
            $button.show();
            $file.hide();
        });
    });
};
