//==
//== Compact slider
//== ======================================= ==//

(function () {

    $('.compact-slider').each(function (i, el) {
        const $el               = $(el);
        const container         = $el.find('.compact-slider__carousel')[0];
        const navContainer      = $el.find('.compact-slider__thumbs')[0];
        const controlsContainer = $el.find('.compact-slider__controls')[0];

        if (!container || !controlsContainer) {
            console.warn('Не найдены слайды или управляющие кнопки в слайдере <.compact-slider>');
            return;
        }

        tns({
            container,
            navContainer,
            controlsContainer,
            navAsThumbnails: true,
            items          : 1,
            mode           : 'gallery',
        });
    });

})();
