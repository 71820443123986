//==
//== Tabs
//== ======================================= ==//

(function () {

    const tabActiveClass  = 'tabs__tab_active';
    const paneActiveClass = 'tabs__pane_active';

    App.tabs = function () {
        $('.tabs').each(function () {

            const $el = $(this);

            if (!!+$el.data('init')) return;

            $el.data('init', 1);

            const $tabs   = $('.tabs__tab', $el);
            const $panels = $('.tabs__pane', $el);

            $tabs.on('click', function (e) {
                e.preventDefault();

                let $tab = $(this);

                if ($tab.hasClass(tabActiveClass)) return;

                let selector = $tab.attr('href');
                let $panel   = $(selector);

                if (!$panel.length) {
                    console.log('C этой вкладкой не связана панель: ' + selector);
                    return;
                }

                $panels.hide().removeClass(paneActiveClass);
                $panel.fadeIn(() => $panel.addClass(paneActiveClass));
                $tabs.removeClass(tabActiveClass);
                $tab.addClass(tabActiveClass);

            });
        });
    };

})();
