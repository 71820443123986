//==
//== Main body script
//== ======================================= ==//
/**
 * @external tns
 */

App.ajaxCatalogTable();
App.tabs();

$('.accordion').accordion();
$('.upload-file').uploadfile();
$('.spin').spin();
$('.simple-select').simpleSelect();

//==
//== Form validation
//== ======================================= ==//

App.customLanguage = {
    requiredField: 'Заполните это поле',
    badEmail     : 'Неверный e-mail фадрес',
};

$.validate({
    lang              : 'ru',
    language          : App.customLanguage,
    modules           : 'html5',
    scrollToTopOnError: false,
    borderColorOnError: '', // Border color on inputs which value is invalid (empty string to prevent the
                            // change of border color).
});

/**
 * "Живой" поиск
 */
(function () {

    let tm = null;

    let $searchInput = $('#textbox');
    $searchInput.attr('autocomplete', 'off');

    $searchInput.on('input', function (e) {
        clearTimeout(tm);
        tm = setTimeout(() => {
            if (e.target.value.length >= 3) {
                $(e.target.form).trigger('submit');
            }
        }, 300);
    });

})();
