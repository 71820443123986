//==
//== Поле выбора количества
//== ======================================= ==//

(function () {
    const DATA_KEY       = 'KEY_SPIN';
    const REPEAT_DELAY_1 = 300;
    const REPEAT_DELAY_2 = 1500;
    const REPEAT_DELAY_3 = 3000;
    const REPEAT_SPEED_1 = 200;
    const REPEAT_SPEED_2 = 100;
    const REPEAT_SPEED_3 = 10;

    let timeout, interval;

    function getIntAttr($el, attr, defaultValue) {
        return parseInt($el.attr(attr)) || defaultValue;
    }

    function getIntValue($el, defaultValue) {
        return parseInt($el.val()) || defaultValue;
    }

    function repeatStop() {
        clearTimeout(timeout);
        clearInterval(interval);
    }

    function Spin($spin) {

        this.$spin   = $spin;
        this.$input  = $('.spin__input', $spin);
        this.options = { min: 1, max: Infinity, step: 1 };

        const updateParams = () => {
            this.options.min  = getIntAttr(this.$input, 'min', 1);
            this.options.max  = getIntAttr(this.$input, 'max', Infinity);
            this.options.step = getIntAttr(this.$input, 'step', 1);
        };

        updateParams();

        this.$input.on('input', () => {
            const value = getIntValue(this.$input);
            if (typeof value === 'undefined') {
                this.$input.val(this.options.min);
                this.$spin.trigger('change', [this.options.min]);
            }
        });

        this.update = function () {
            updateParams();
        };

        this.increment = function () {
            let value = getIntValue(this.$input, this.options.min);
            value     = Math.min(value + this.options.step, this.options.max);
            this.$input.val(value);
            this.$spin.trigger('change', [value]);
        };

        this.decrement = function () {
            let value = getIntValue(this.$input, this.options.min);
            value     = Math.max(value - this.options.step, this.options.min);
            this.$input.val(value);
            this.$spin.trigger('change', [value]);
        };

        this.repeatStart = function (func) {
            if (func === 'increment' || func === 'decrement') {
                clearTimeout(timeout);
                // Первая скорость
                timeout = setTimeout(() => {
                    interval = setInterval(() => {this[func]();}, REPEAT_SPEED_1);

                    // Вторая скорость
                    timeout = setTimeout(() => {
                        clearInterval(interval);
                        interval = setInterval(() => {this[func]();}, REPEAT_SPEED_2);

                        // Третья скорость
                        timeout = setTimeout(() => {
                            clearInterval(interval);
                            interval = setInterval(() => {this[func]();}, REPEAT_SPEED_3);
                        }, REPEAT_DELAY_3);
                    }, REPEAT_DELAY_2);
                }, REPEAT_DELAY_1);
            }
        };

        // noinspection JSUnusedGlobalSymbols
        this.getOption = function (opt) {
            if (typeof opt === 'undefined' || !opt) {
                return this.options;
            }

            if (this.options.hasOwnProperty(opt)) {
                return this.options[opt];
            } else {
                return null;
            }
        };
    }

    function initSpin($spin) {
        if (!$spin.length) {
            console.warn('Spin undefined: ', $spin);
            return;
        }

        let spin = $spin.data(DATA_KEY);

        if (!spin) {
            spin = new Spin($spin);
            $spin.data(DATA_KEY, spin);
        } else {
            spin.update();
        }

        return spin;
    }

    $(document).on('mouseup.spin', repeatStop);

    $(document).on('focus.spin', '.spin__input', function () {
        initSpin($(this).closest('.spin'));
    });

    $(document).on('click.spin', '.spin__btn', function () {
        const $btn = $(this);
        const spin = initSpin($btn.closest('.spin'));
        if ($btn.hasClass('spin__btn_increase')) spin.increment();
        if ($btn.hasClass('spin__btn_decrease')) spin.decrement();
    });

    $(document).on('mousedown.spin', '.spin__btn', function () {
        const $btn = $(this);
        const spin = initSpin($btn.closest('.spin'));
        if ($btn.hasClass('spin__btn_increase')) spin.repeatStart('increment');
        if ($btn.hasClass('spin__btn_decrease')) spin.repeatStart('decrement');
    });

    jQuery.fn.spin = function () {
        return this.each(function () {
            return initSpin($(this));
        });
    };
})();
