//==
//== Carousels
//== ======================================= ==//

(function () {

    const presets = {
        'goods-wide': {
            576 : { items: 2 },
            768 : { items: 3 },
            992 : { items: 5 },
            1200: { items: 6 },
            1350: { items: 7 },
        },

        'top-sale': {
            576 : { items: 2 },
            768 : { items: 3 },
            1200: { items: 4 },
            1350: { items: 5 },
        },

        'brands': {
            0   : { items: 2 },
            576 : { items: 3 },
            768 : { items: 4 },
            1200: { items: 5 },
            1350: { items: 6 },
        },

        'reviews': {
            0   : { gutter: 20 },
            1200: { items: 2, gutter: 90 },
        },

        'news': {
            576 : { items: 2 },
            1200: { items: 3 },
        },

        'services': {
            576 : { items: 2 },
            768 : { items: 3 },
            1200: { items: 4 },
        },

        'certificates': {
            576 : { items: 2 },
            768 : { items: 3 },
            1200: { items: 5 },
            1350: { items: 6 },
        },

        'certificates-small': {
            576 : { items: 2 },
            768 : { items: 3 },
            992 : { items: 5 },
            1200: { items: 4 },
            1350: { items: 4 },
        },
    };

    $('.carousel.on').each(function (index, el) {
        const $el               = $(el);
        const container         = $el.find('.carousel__main')[0];
        const controlsContainer = $el.find('.carousel__controls')[0];

        if (!container || !controlsContainer) {
            console.warn('Не найдены слайды или управляющие кнопки в карусели <.carousel>');
            return;
        }

        let responsive   = null;
        let gutter       = 0;
        const presetName = $el.data('preset');
        const loop       = $el.data('loop') === 'true';
        const gutterWidth = parseInt($el.data('gutter'));

        if (presetName && presets.hasOwnProperty(presetName)) {
            responsive = { ...presets[presetName] };
        }

        if (gutterWidth && !isNaN(gutterWidth) && gutterWidth > 0) {
            gutter = gutterWidth;
        }

        tns({
            container,
            controlsContainer,
            responsive,
            gutter,
            loop,
            nav  : false,
            items: 1,
            slideBy: 'page',
            speed: 800,
        });
    });

})();
