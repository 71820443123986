//==
//== Slider on homepage
//== ======================================= ==//

(function () {

    const selector = 'promo-slider';

    $('.promo-slider').each(function () {
        const $el               = $(this);
        const container         = $(`.${selector}__carousel`, $el)[0];
        const controlsContainer = $(`.${selector}__controls`, $el)[0];

        tns({
            container,
            controlsContainer,
            items               : 1,
            speed               : 800,
            loop                : false,
            rewind              : true,
            mouseDrag           : true,
            autoHeight          : true,
            autoplay            : true,
            autoplayTimeout     : 5000,
            autoplayHoverPause  : true,
            autoplayButtonOutput: false,
        });
    });

})();
