//==
//== Mobile menu
//== ======================================= ==//

(function () {

    try {
        const coolMenu = new CoolMenu({
            source    : [
                {
                    container    : document.querySelector('.catalog-navigation__dropdown > ul'),
                    selectorTitle: '.catalog-menu__link',
                    selectorList : '.catalog-menu',
                    group        : {
                        title          : 'Каталог',
                        link           : '#',
                        customItemClass: 'cool-menu-group-item',
                        customLinkClass: 'cool-menu-group-link',
                    },
                },
                document.querySelector('#side-menu > ul'),
                document.querySelector('.header__menu > ul'),
                document.querySelector('.navbar__menu'),
            ],
            headerText: 'Меню',
            button    : document.querySelector('#mobile-menu-button'),

            onShow: function () {
                App.lock();
            },

            onClose: function () {
                App.unlock();
            },
        });

        coolMenu.create();

        if (window.matchMedia) {
            window.matchMedia('(min-width: 992px)').addListener(function (evt) {
                if (evt.matches) coolMenu.toggleMenu(false);
            });
        }

    } catch (e) {
        console.error(e);
    }

})();
