//==
//== Floating cart
//== ======================================= ==//

(function () {
    $(document).on('click', '.scf-widget__button', function () {
        let $widget = $(this).closest('.scf-widget');
        $widget.toggleClass('scf-widget_state_open');
        if ($widget.hasClass('scf-widget_state_open')) {
            App.$backdrop.show();
        } else {
            App.$backdrop.hide();
        }
    });
})();
