//==
//== Catalog navigation
//== ======================================= ==//

(function () {

    let isOpenCatalogMenu = false;

    let $button = $('#catalog-button');

    function toggleCatalogMenu(state) {
        $('body').toggleClass('isOpenCatalogMenu', state);
        $('.burger', $button).toggleClass('isActive', state);

        if (state) {
            App.lock();
        } else {
            App.unlock();
        }
    }

    $button.on('click', function (e) {

        e.preventDefault();

        isOpenCatalogMenu = !isOpenCatalogMenu;
        toggleCatalogMenu(isOpenCatalogMenu);

    });

    if (window.matchMedia) {
        window.matchMedia('(max-width: 991px)').addListener(function (evt) {
            if (evt.matches) {
                isOpenCatalogMenu = false;
                toggleCatalogMenu(isOpenCatalogMenu);
            }
        });
    }

})();
