//==
//== Загрузка данных для таблицы каталога
//== В карточке товара
//== ======================================= ==//

App.loadCatalogRows = function (url, start = 21, count = 20) {

    if (!url) {
        console.log('%c Error ', 'color: white; background-color: red', 'App.loadCatalogRows: url is empty');
        return;
    }

    return $.ajax({
        url,
        method: 'post',

        data: {
            start,
            count,
        },
    });
};

App.ajaxCatalogTable = function () {

    $('.ajax-catalog-table').each(function () {

        const $component = $(this);

        if ($component.data('init')) return;
        $component.data('init', 1);

        let url   = $component.data('source');
        let start = +$component.data('start');
        let count = +$component.data('count');
        let total = +$component.data('total');

        let currentCount = start - 1;

        const $button = $('.btn_ajax', $component);
        const $table  = $('.catalog-table', $component);
        const $info   = $('.display-info', $component);

        $button.on('click', function () {
            $button.addClass('isLoading').prop('disabled', true);

            App.loadCatalogRows(url, start, count)
                .then(function (response) {

                    currentCount += count;
                    if (currentCount > total) currentCount = total;
                    start += count;
                    if (start > total) start = total;

                    $table.append(response);
                    $info.text(`Показано с 1 по ${currentCount} из ${total}`);
                    $button.removeClass('isLoading').prop('disabled', false);

                })
                .catch(function (error) {

                    console.error(error);
                    $button.removeClass('isLoading').prop('disabled', false);

                })
            ;
        });

    });

};
