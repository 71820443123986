//==
//== Компенсатор ширины скроллбара для
//== некоторых элементов верстки
//== ======================================= ==//

App.compensateWidthScrollbar = function () {
    let width  = this.getScrollbarWidth();
    let css    = `.section::before{margin-right:${width / 2}px`;
    let style  = document.createElement('style');
    style.type = 'text/css';
    style.appendChild(document.createTextNode(css));
    (document.head || document.getElementsByTagName('head')[0]).appendChild(style);
};

App.compensateWidthScrollbar();
